import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";

import Button from "@/components/Button";
import CustomInput from "@/components/form/CustomInput";
import AsoebiLayout from "@/layout/AsoebiLayout";
import { sendMagicLink } from "@/axios/post-request";
import { validateEmail } from "@/helper";

const inputDetails = { name: "email", placeholder: "Email address", type: "email" };

const schema = yup.object({
    email: yup.string().email().required("Email is required"),
});

export default function AsoebiLogin() {
    const [loading, setLoading] = useState(false);
    const methods = useForm<{ email: string }>({
        resolver: yupResolver(schema),
    });

    async function onSubmitHandler(data: { email: string }) {
        try {
            if (!validateEmail(data.email)) {
                return toast.error("Invalid email address");
            }
            setLoading(true);
            const response = await sendMagicLink(data.email);
            setLoading(false);

            if (!response.data.status) {
                return toast.error(response.data.message);
            }

            toast.success(response.data.message || "Magic link has been sent to your email");
            methods.reset();

            return;
        } catch (error: unknown) {
            const __error = error as { message: string };
            console.log("error", error);
            setLoading(false);
            toast.error(__error?.message || "Oops unable to send magic link");
        }
    }

    const error = methods.formState.errors["email"];

    return (
        <AsoebiLayout>
            <section className="vh-100 ">
                <FormProvider {...methods}>
                    <form
                        className="planner_login col-lg-6 col-md-9 col-11 my-5 p-md-4 py-1 px-3 mx-auto bg-white shadow rounded"
                        onSubmit={methods.handleSubmit(onSubmitHandler)}>
                        <h2 className="mt-5 text-dark text-center">Login</h2>
                        <p className="text-center text-dark mt-4">Login with your asoebi order form email address</p>
                        <p className="text-center text-dark">We will send a magic link to your email address</p>
                        <CustomInput className="my-4" input={inputDetails} error={error} />
                        <Button
                            type="submit"
                            text="Login"
                            className="btn btn-primary px-4 py-2 font-weight-bold text-white mx-auto mt-md-4 mb-5"
                            isLoading={loading}
                        />
                    </form>
                </FormProvider>
            </section>
        </AsoebiLayout>
    );
}
