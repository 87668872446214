import { ChangeEvent, useState } from "react";

import Input2 from "@/components/form/Input2";
import GalleryIcon from "@/assets/icon/GalleryIcon";
import PasswordInput2 from "./form/PasswordInput2";
import Spinner from "./Spinner";

type loadingType = "name" | "phone" | "password" | "Instagram" | "Tiktok";

interface PropsProfileRow {
	index: number;
	formInput: { name: string; label: string; placeholder: string; type?: string };
	value: string;
	placeholder: string;
	withPhoto?: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	changeProfilePhotoHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
	onSaveHandler: (updateProfileHandler: () => void) => void;
	loading: loadingType | null;
	previewImage?: string;
}

export default function ProfileRow({
    formInput,
    placeholder,
    onChange,
    value,
    index,
    previewImage,
    changeProfilePhotoHandler,
    onSaveHandler,
    loading,
    withPhoto = false,
}: PropsProfileRow) {
    const [editProfileIndex, setEditProfileIndex] = useState<number | null>(null);

    function onEditHandler(index: number) {
        setEditProfileIndex(index);
    }

    const valueText = placeholder === "Password" ? "*******************" : value;
    const className = withPhoto ? "input_with_img" : "";

    function onProfileSaveHandler() {
        setEditProfileIndex(null);
    }

    const isLoading = loading === formInput.name;

    return (
        <div className="profile_row">
            {editProfileIndex === index ? (
                <>
                    {withPhoto ? (
                        <div className="input_with_img">
                            <div className="upload_image">
                                <input onChange={changeProfilePhotoHandler} type="file" accept="image/png, image/webp, image/jpg, image/jpeg" />
                                {previewImage ? (
                                    <img src={previewImage} alt="user image" />
                                ) : (
                                    <div className="image_wrapper">
                                        <GalleryIcon />
                                        <p>Icon</p>
                                    </div>
                                )}
                            </div>
                            <div className="input_wrapper">
                                <Input2 value={value} input={formInput} onChange={onChange} />
                            </div>
                        </div>
                    ) : (
                        <div className="input_wrapper">
                            {formInput.type === "password" ? (
                                <PasswordInput2 value={value} input={formInput} onChange={onChange} />
                            ) : (
                                <Input2 value={value} input={formInput} onChange={onChange} />
                            )}
                        </div>
                    )}
                </>
            ) : (
                <div className={className}>
                    {withPhoto && (
                        <div className="image_wrapper">
                            {previewImage ? (
                                <img src={previewImage} className="upload_image user_img" alt="user image" />
                            ) : (
                                <div className="upload_image">
                                    <GalleryIcon />
                                    <p>Icon</p>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="text_content">
                        <p>{placeholder}</p>
                        <h6>{valueText}</h6>
                    </div>
                </div>
            )}
            {editProfileIndex === index ? (
                <button className="save_changes" type="button" onClick={() => onSaveHandler(onProfileSaveHandler)} disabled={isLoading}>
					Save Changes {isLoading && <Spinner size={20} className="ml-2" />}
                </button>
            ) : (
                <button type="button" className="edit_btn" onClick={() => onEditHandler(index)}>
					Edit
                </button>
            )}
        </div>
    );
}
