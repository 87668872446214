import React, { ChangeEvent, useState } from "react";
import { Icon } from "@iconify/react";
import GalleryIcon from "@/assets/icon/GalleryIcon";

const iconTabs = ["Icons", "Emoji", "Upload"];
const icons = [
    "ri:ancient-gate-fill",
    "ri:ancient-gate-line",
    "ri:ancient-pavilion-fill",
    "ri:ancient-pavilion-line",
    "ri:bank-fill",
    "ri:bank-line",
    "ri:building-2-fill",
    "ri:building-2-line",
    "ri:building-3-fill",
    "ri:building-3-line",
    "ri:building-4-fill",
    "ri:building-4-line",
    "ri:building-fill",
    "ri:building-line",
    "ri:community-fill",
    "ri:community-line",
    "ri:government-fill",
    "ri:government-line",
    "ri:home-2-fill",
    "ri:home-2-line",
    "ri:home-3-fill",
    "ri:home-3-line",
    "ri:home-4-fill",
    "ri:home-4-line",
    "mdi:heart",
    "fluent-emoji-high-contrast:wedding",
    "mdi:ring",
    "ph:dress-fill",
    "game-icons:ample-dress",
    "fluent:food-20-regular",
    "fluent:food-cake-24-filled",
    "mdi:food-outline",
    "mingcute:car-line",
    "icon-park-outline:sound",
    "jam:dj",
    "solar:dollar-line-duotone",
    "mdi:naira",
    "arcticons:drinks",
    "lucide:party-popper",
    "icon-park-outline:party-balloon",
];

const emojis = [
    "❤️",
    "💖",
    "👩‍❤️‍👨",
    "👰🏻",
    "👰‍♀️",
    "🤵‍♂️",
    "🤵🏻",
    "💍",
    "👠",
    "👗",
    "🚀",
    "✔️",
    "✨",
    "😂",
    "😎",
    "😍",
    "👋🏼",
    "🏠",
    "🏡",
    "⏱️",
    "⏰",
    "🚘",
    "🚙",
    "🥘",
    "🎂",
    "🍲",
    "🍾",
    "🍷",
    "🎉",
    "🎊",
    "🎁",
    "🌹",
    "🥀",
    "🌺",
    "🍟",
    "🍔",
    "✈️",
    "⛴️",
    "📦",
    "💄",
];

type IconProps = {
	selectCategoryIcon: (icon: string) => void;
	selectedIcon: string;
};

function IconsGrid({ selectCategoryIcon }: IconProps) {
    return (
        <div className="budget_icons_grid">
            {icons.map((icon, index) => (
                <button key={index}>
                    <Icon icon={icon} onClick={() => selectCategoryIcon(icon)} fill="#000" width="18" height="18" />
                </button>
            ))}
        </div>
    );
}

function EmojiGrid({ selectCategoryIcon }: IconProps) {
    return (
        <div className="budget_icons_grid">
            {emojis.map((emoji, index) => (
                <button onClick={() => selectCategoryIcon(emoji)} key={index}>
                    {emoji}
                </button>
            ))}
        </div>
    );
}

interface Props extends IconProps {
	updateUploadedImage: (file: File | null, url: string) => void;
}

type UploadCustomImageType = {
	updateUploadedImage: (file: File | null, url: string) => void;
};

function UploadCustomImage({ updateUploadedImage }: UploadCustomImageType) {
    const [previewImage, setPreviewImage] = useState("");
    const [imageFile, setImageFile] = useState<File | null>(null);

    function changePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL?.createObjectURL(file);
        setPreviewImage(url);
        setImageFile(file);
    }

    function onSubmitUploadedImages() {
        updateUploadedImage(imageFile, previewImage);
    }

    return (
        <div className="upload_custom_image">
            <div className="upload_image upload_category_image">
                <input onChange={changePhotoHandler} type="file" accept="image/png, image/webp, image/jpg, image/jpeg" /> <GalleryIcon />
            </div>
            {previewImage && <img src={previewImage} alt="event image" />}
            {previewImage && imageFile && (
                <button className="create_btn btn" onClick={onSubmitUploadedImages}>
					Submit
                </button>
            )}
        </div>
    );
}

export default function BudgetCategoryDropdown({ selectedIcon, selectCategoryIcon, updateUploadedImage }: Props) {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    function updateActiveTab(tabIndex: number) {
        setActiveTabIndex(tabIndex);
    }

    return (
        <div className="budget_category_icon_dropdown">
            <div className="tab">
                {iconTabs.map((item, index) => {
                    const activeTab = index === activeTabIndex ? "active" : "";
                    return (
                        <button key={index} onClick={() => updateActiveTab(index)} className={activeTab}>
                            {item}
                        </button>
                    );
                })}
            </div>
            <div className="tab_content">
                {activeTabIndex === 0 ? (
                    <IconsGrid selectedIcon={selectedIcon} selectCategoryIcon={selectCategoryIcon} />
                ) : activeTabIndex === 1 ? (
                    <EmojiGrid selectedIcon={selectedIcon} selectCategoryIcon={selectCategoryIcon} />
                ) : (
                    <UploadCustomImage updateUploadedImage={updateUploadedImage} />
                )}
            </div>
        </div>
    );
}
