import { AnnoyedIcon } from "lucide-react";
import { Link } from "react-router-dom";
import CalculatorWrapper from "@/components/CalculatorWrapper";
import "@/styles/error_page.scss";

export default function ErrorPage() {
    return (
        <CalculatorWrapper title="Error Page Not Found">
            <section className="error_page">
                <div>
                    <AnnoyedIcon stroke="#cb3050" size={60} />
                    <h1>Oops this page does not exist</h1>
                    <Link to="/">Home</Link>
                </div>
            </section>
        </CalculatorWrapper>
    );
}
