import OverlayTooltip from "./OverlayTooltip";

interface Props {
	price: string | number;
	title: string;
	className?: string;
	info: string;
	icon: JSX.Element;
}

export default function BudgetCard({ price, title, info, icon, className }: Props) {
    const cardClassName = className ? className : "";
    return (
        <div className={`budget_card  ${cardClassName}`}>
            <div className="top_row">
                <h4>
                    {price ? (
                        <>
                            {price} <span>NGN</span>
                        </>
                    ) : (
                        <>
							00.<span>00 NGN</span>
                        </>
                    )}
                </h4>
                {icon}
            </div>
            <div className="title_info_row">
                <h6>{title}</h6>
                <OverlayTooltip info={info} />
            </div>
        </div>
    );
}
