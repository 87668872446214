import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import { getAsoebiOrders, getAsoebiVendorOrderForms } from "@/axios/get-request";
import AsoebiDashboardLayout from "@/layout/AsoebiDashboardLayout";
import { convertCurrencyToNumber, formatCurrency, generateColorFromId } from "@/helper";
import AseobiDashboardLoader from "@/components/loaders/AseobiDashboardLoader";
import PlusIcon from "@/assets/icon/PlusIcon";
import useAsoebiAdminAuth from "@/hooks/useAsoebiAdminAuth";

export default function UserDashboard() {
    const { verifyData } = useAsoebiAdminAuth();
    const { data: orderFormData, status: orderFormStatus } = useQuery({
        queryFn: () => getAsoebiVendorOrderForms(verifyData?.data.result?.email as string),
        queryKey: ["get_vendor_order_forms"],
        enabled: !!verifyData?.data.result?.email,
    });

    const { data, status } = useQuery({
        queryFn: () => getAsoebiOrders(verifyData?.data?.result?.email as string),
        queryKey: ["get_vendor_asoebi_orders"],
        enabled: !!verifyData?.data?.result?.email,
    });

    const no_of_created_order_form = orderFormStatus === "success" ? orderFormData?.data?.result?.length ?? 0 : 0;
    const no_of_answered_orders = status === "success" ? data?.data?.result ? data?.data?.result?.length : 0 : 0;
    let amount_paid = 0;
    const orders_in_dollar = [];

    if (status === "success" && data?.data?.result) {
        amount_paid = data?.data?.result?.reduce((a, b) => {
            if (!b.amount_paid?.includes("$")) {
                const paid_amount = b.amount_paid ? convertCurrencyToNumber(b.amount_paid, "₦") : 0;
                return a + paid_amount;
            } else {
                const paid_amount = b.amount_paid ? convertCurrencyToNumber(b.amount_paid, "$") : 0;
                orders_in_dollar.push(true);
                return a + paid_amount;
            }
        }, 0);
    }

    const currency = orders_in_dollar?.length > 0 ? "$" : "₦";
    const amountText = currency === "$" ? "Total Amount" : "Total amount collected";

    const plannerName = verifyData ? verifyData.data.result.name : "";
    const userDetails = verifyData?.data?.result;

    const user_link = userDetails ? `email=${userDetails?.email}` : "";
    const create_order_form_link = currency === "$" ? `/asoebi/track-orders?${user_link}` : `/asoebi-tracker?${user_link}`;

    return (
        <>
            <AsoebiDashboardLayout type="user" name={plannerName}>
                <>
                    {status === "error" ? (
                        <p>Unable to fetch admin details</p>
                    ) : status === "loading" ? (
                        <AseobiDashboardLoader />
                    ) : (
                        <div className="guest_dashboard">
                            <div className="d-flex justify-content-between align-items-center">
                                <h1 className="mb-lg-4 mt-4 mb-5 text-dark">Hello {plannerName} 👋🏼</h1>
                                <a href={create_order_form_link} target="_blank" className="btn btn-primary rounded" title="create a new orderform">
                                    <PlusIcon fill="#fff" />
                                </a>
                            </div>
                            <section className="card_view">
                                <div className="card  bg-white">
                                    <h2>{no_of_answered_orders}</h2>
                                    <h4 className="mb-0">Asoebi Orders</h4>
                                </div>
                                <div className="card  bg-white">
                                    <h2>{no_of_created_order_form}</h2> <h4 className="mb-0">Created Asoebi Forms</h4>
                                </div>
                                {
                                    <div className="card  bg-white">
                                        <h2>{formatCurrency(amount_paid, currency)}</h2>
                                        <h4 className="mb-0">{amountText}</h4>
                                    </div>
                                }
                            </section>
                            <section className="d-flex flex-row mt-5">
                                {orderFormStatus === "error" ? (
                                    <p>Unable to fetch order forms</p>
                                ) : orderFormStatus === "loading" ? (
                                    <AseobiDashboardLoader />
                                ) : (
                                    <div className="d-flex flex-column order_form">
                                        <h3 className="text-dark">Order forms</h3>
                                        <div className="order_form_details">
                                            {orderFormData?.data.result?.map((orderform) => {
                                                const orderlink = `${orderform.eventDetails.event_name}&id=${orderform.id}`;
                                                const numberOfOrdersText = orderform.no_of_paid_guest > 1 ? "Orders" : "Order";
                                                const numberOfOrders = orderform.no_of_paid_guest ? orderform.no_of_paid_guest : 0;

                                                const orderFormColor = generateColorFromId(orderform.id);
                                                return (
                                                    <Link
                                                        key={orderform.id}
                                                        className="orderform_card"
                                                        to={`/asoebi/dashboard/order-form?event_name=${orderlink}`}>
                                                        <div>
                                                            <div className="order_form_banner" style={{ backgroundColor: orderFormColor }}></div>
                                                            <div className="details">
                                                                <h6>{orderform.eventDetails.event_name}</h6>
                                                                <p>
                                                                    {numberOfOrders} {numberOfOrdersText}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </section>
                        </div>
                    )}
                </>
            </AsoebiDashboardLayout>
        </>
    );
}
