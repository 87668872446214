import { OverlayTrigger, Tooltip } from "react-bootstrap";
import UnionIcon from "@/assets/icon/UnionIcon";

interface Props {
	info: string;
}

export default function OverlayTooltip({ info }: Props) {
    return (
        <OverlayTrigger
            placement="right"
            overlay={
                <Tooltip className="tooltip" id="budget">
                    {info}
                </Tooltip>
            }>
            <div className="question">
                <UnionIcon />
            </div>
        </OverlayTrigger>
    );
}
