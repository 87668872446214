interface Props {
	className?: string;
	size?: number;
}

export default function Spinner({ className = "mx-auto", size = 60 }: Props) {
    return (
        <svg fill="none" viewBox="0 0 66 66" className={className} style={{ color: " #cb3050", overflow: "visible", width: `${size}` }}>
            <circle cx="33" cy="33" fill="none" r="28" stroke="#D7D5D4" strokeWidth="4"></circle>
            <circle
                cx="33"
                cy="33"
                fill="none"
                r="28"
                stroke="currentColor"
                strokeDasharray="1, 174"
                strokeDashoffset="306"
                strokeLinecap="round"
                strokeWidth="4"
                style={{ animation: "1.4s linear 0s infinite normal none running spinners-react-circular" }}></circle>
        </svg>
    );
}
