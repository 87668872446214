import { format } from "date-fns";

import type { auditType } from "@/types";

interface Props {
	audit: auditType[0];
	showType: boolean;
}

export default function FloorplanAuditDropdown({ audit, showType }: Props) {
    const _audit_date = new Date(audit.timestamp);
    const audit_date = format(_audit_date, "dd-MM-yyyy, KK:mm aa");
    const actionText = audit.action === "add_floorplan" ? "added" : audit.action === "delete_floorplan" ? "deleted" : "renamed";
    const auditTypeClassname = showType ? "show_type" : "";

    return (
        <div
            className={`${auditTypeClassname} audit_list_card 
            bg-white p-3 mb-3 rounded justify-content-between d-flex align-items-lg-center`}>
            <div className="audit_details">
                <span className="font-weight-bold mr-1">{audit.title}</span>
				floorplan was {actionText} by {audit?.name ?? audit.email} at {audit_date}
            </div>
            <div className="audit_type_group">{showType && <div className="type">{audit.type ? "Floor plan" : ""}</div>}</div>
        </div>
    );
}
