import { ChangeEvent, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import DashboardModal from "@/components/modals/DashboardModal";
import useClickOutside from "@/hooks/useClickOutside";
import PlusIcon from "@/assets/icon/PlusIcon";
import Input2 from "@/components/form/Input2";
import TrashIcon2 from "@/assets/icon/TrashIcon2";
import { categoryType, VendorType } from "@/types";
import { formatNumberWithCommas, formatStringToNumber } from "@/helper";
import { addCategory, uploadBudgetCategoryImage } from "@/axios/post-request";
import { useEvent } from "@/hooks/useEvent";
import useAudit from "@/hooks/useAudit";
import Spinner from "@/components/Spinner";
import VendorPicker from "./VendorPicker";
import GalleryIcon from "@/assets/icon/GalleryIcon";
import BudgetCategoryDropdown from "@/components/BudgetCategoryDropdown";
import "@/styles/add_budget_category.scss";
import { Icon } from "@iconify/react";

const formDetails = {
    category: { label: "Category", name: "category", placeholder: "Budget category" },
    costItem: { label: "Cost item", placeholder: "--", name: "name" },
    estimate: { label: "Estimate", placeholder: "000 000 000", name: "estimate" },
    actualCost: { label: "Actual Cost", placeholder: "000 000 000", name: "actual_cost" },
    vendor: { label: "Vendor", placeholder: "--", name: "vendor_name" },
};

interface Props {
	onClose: () => void;
	budgetCategories: Array<categoryType> | null;
}

type formValuesType = {
	category: string;
	costItems: Array<{
		name: string;
		estimate: string;
		actual_cost: string;
		vendor: VendorType | null;
	}>;
};

function formatCategoryCostItems(cost_items: formValuesType["costItems"], activeCeremonyId: string) {
    return cost_items.map((item) => {
        return {
            ...item,
            id: uuidv4(),
            name: item.name,
            estimate: item.estimate ? formatStringToNumber(item.estimate) : 0,
            actual: item.actual_cost ? formatStringToNumber(item.actual_cost) : 0,
            note: "",
            paid: 0,
            balance: 0,
            balance_due: null,
            paid_by_me: "",
            vendor_ig: "",
            tag: activeCeremonyId ? activeCeremonyId : "all",
        };
    });
}

export default function AddBudgetCategoryModal({ onClose }: Props) {
    const { selectedEventId, activeCeremonyId } = useEvent();
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const { updateAuditHandler } = useAudit();
    const [showImageDropdown, setShowImageDrodown] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState("");
    const [categoryIconImage, setCategoryIconImage] = useState<File | null>(null);
    const [previewCategoryIconImage, setPreviewCategoryIconImage] = useState("");
    const ref = useRef(null);
    useClickOutside(ref, closeImageDropdownHandler);
    const [formValues, setFormValues] = useState<formValuesType>({
        category: "",
        costItems: [
            {
                name: "",
                actual_cost: "",
                estimate: "",
                vendor: null,
            },
        ],
    });

    function closeImageDropdownHandler() {
        setShowImageDrodown(false);
    }

    function showImageDropdownHandler() {
        setShowImageDrodown(true);
    }

    function updateUploadedImage(file: File | null, url: string) {
        setPreviewCategoryIconImage(url);
        setCategoryIconImage(file);
        setShowImageDrodown(false);
    }

    function selectCategoryIcon(icon: string) {
        setSelectedIcon(icon);
    }

    function onSelectVendor(vendorDetails: VendorType | null, index: number) {
        const tempCostItem = [...formValues.costItems];
        tempCostItem[index] = {
            ...tempCostItem[index],
            vendor: vendorDetails,
        };
        setFormValues({
            ...formValues,
            costItems: tempCostItem,
        });
    }

    async function createBudgetCategoryResponse() {
        try {
            if (formValues.costItems.length === 0) {
                return toast.error("Add at least one cost item");
            }
            if (!formValues.category) {
                return toast.error("Category is required");
            }
            setLoading(true);
            const budget_cost_items = formatCategoryCostItems(formValues.costItems, activeCeremonyId);
            const actual_total = budget_cost_items.reduce((a, b) => {
                return a + b.actual;
            }, 0);
            const estimate_total = budget_cost_items.reduce((a, b) => {
                return a + b.estimate;
            }, 0);
            const iconType = selectedIcon.includes(":") ? "icon" : "emoji" as "icon" | "emoji" | "image";
            const categoryIcon = selectedIcon ? { icon: { type: iconType, icon: selectedIcon } } : {};
            const categoryId = uuidv4();
            const createBudgetCategoryResponse = await addCategory(
                {
                    id: categoryId,
                    name: formValues.category,
                    cost_items: budget_cost_items,
                    actual_total: actual_total,
                    estimate_total: estimate_total,
                    paid_total: 0,
                    ...categoryIcon,
                },
                selectedEventId,
            );
            if (categoryIconImage) {
                const formData = new FormData();
                formData.append("file", categoryIconImage);
                await uploadBudgetCategoryImage(selectedEventId, categoryId, formData);
            }
            setLoading(false);
            if (!createBudgetCategoryResponse.data.status) {
                return toast.error(createBudgetCategoryResponse?.data?.message);
            }
            queryClient.resetQueries({ queryKey: [`get_budget_categories_${selectedEventId}_${activeCeremonyId}`] });
            updateAuditHandler("budget_tracker", "add_budget_cost_item_category", formValues.category);
            onClose();
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Oops, unable to add budget category");
            onClose();
        }
    }

    function updateCategoryName(event: ChangeEvent<HTMLInputElement>) {
        setFormValues({
            ...formValues,
            category: event.target.value,
        });
    }

    function updateCategoryItemDetailsHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        setFormValues((prev) => {
            const updatedCostItems = [...prev.costItems];
            updatedCostItems[index] = {
                ...updatedCostItems[index],
                [event.target.name]: event.target.value,
            };
            return {
                ...prev,
                costItems: updatedCostItems,
            };
        });
    }

    function updateCategoryCostItemHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const inputValue = event.target.value;
        const formattedValue = formatNumberWithCommas(inputValue);
        setFormValues((prev) => {
            const updatedCostItems = [...prev.costItems];
            updatedCostItems[index] = {
                ...updatedCostItems[index],
                [event.target.name]: event.target.value ? formattedValue : "",
            };
            return {
                ...prev,
                costItems: updatedCostItems,
            };
        });
    }

    function addCostItemHandler() {
        setFormValues({
            ...formValues,
            costItems: [
                ...formValues.costItems,
                {
                    name: "",
                    estimate: "",
                    actual_cost: "",
                    vendor: null,
                },
            ],
        });
    }

    function deleteCostItemHandler(index: number) {
        if (formValues.costItems.length >= 2) {
            const remainingCostItem = formValues.costItems.filter((_, idx) => idx !== index);
            setFormValues({
                ...formValues,
                costItems: remainingCostItem,
            });
        } else {
            return toast.error("There should be at least one cost item");
        }
    }

    return (
        <DashboardModal
            title="Add Category"
            description="Create a new budget category"
            className="small_width add_budget_category_modal"
            onClose={onClose}>
            <div className="add_budget_category">
                <div className="budget_category_input">
                    <div className="input_group">
                        <Input2 value={formValues.category} input={formDetails.category} onChange={updateCategoryName} />
                        <button className="upload_image" onClick={showImageDropdownHandler}>
                            {previewCategoryIconImage ? (
                                <img src={previewCategoryIconImage} alt="Preview image" />
                            ) : selectedIcon ? (
                                selectedIcon.includes(":") ? (
                                    <Icon icon={selectedIcon} fill="#000" width="18" height="18" />
                                ) : (
                                    selectedIcon
                                )
                            ) : (
                                <GalleryIcon />
                            )}
                        </button>
                    </div>
                    {showImageDropdown && (
                        <div ref={ref}>
                            <BudgetCategoryDropdown
                                selectedIcon={selectedIcon}
                                selectCategoryIcon={selectCategoryIcon}
                                updateUploadedImage={updateUploadedImage}
                            />{" "}
                        </div>
                    )}
                </div>
                <hr />
                <div className="add_cost_items">
                    <div className="top_row">
                        <h4>Cost Items</h4>
                        <button type="button" onClick={addCostItemHandler}>
							Add cost <PlusIcon fill="#000" />
                        </button>
                    </div>
                    <div className="add_cost_item_group">
                        {formValues.costItems.map((costItem, index) => {
                            const vendorDetails = { id: costItem?.vendor?.id as string, name: costItem?.vendor?.name as string };
                            return (
                                <div key={index} className="cost_item_group">
                                    <div className="cost_item_input_group">
                                        <Input2
                                            value={costItem.name}
                                            input={formDetails.costItem}
                                            onChange={(event) => updateCategoryItemDetailsHandler(event, index)}
                                        />
                                        <Input2
                                            value={costItem.estimate}
                                            input={formDetails.estimate}
                                            onChange={(event) => updateCategoryCostItemHandler(event, index)}
                                        />
                                    </div>
                                    <div className="cost_item_input_group">
                                        <Input2
                                            value={costItem.actual_cost}
                                            input={formDetails.actualCost}
                                            onChange={(event) => updateCategoryCostItemHandler(event, index)}
                                        />
                                        <div className="vendor_input">
                                            <div className="vendor_input_wrapper">
                                                <label>Vendor</label>
                                                <VendorPicker
                                                    vendor={vendorDetails}
                                                    onSelectEventVendor={(__vendorDetails) => onSelectVendor(__vendorDetails, index)}
                                                    addNew={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="action_button_group">
                                            <button type="button" onClick={() => deleteCostItemHandler(index)}>
                                                <TrashIcon2 />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="modal_footer">
                <div className="button_group">
                    <button type="button" onClick={onClose} className="cancel_btn">
						Cancel
                    </button>
                    <button type="button" className="btn create_btn d-flex align-items-center" onClick={createBudgetCategoryResponse}>
						Create {loading && <Spinner className="ml-2" size={20} />}
                    </button>
                </div>
            </div>
        </DashboardModal>
    );
}
