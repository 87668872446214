import { Helmet } from "react-helmet";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useRef, useState, type PropsWithChildren } from "react";
import { usePaystackPayment } from "react-paystack";
import { addMonths, format } from "date-fns";

import NewDashboardSidebar from "@/components/NewDashboardSidebar";
import ChevronDownIcon from "@/assets/icon/ChevronDownIcon";
import UserRoundIcon from "@/assets/icon/UserRoundIcon";
import ChevronLeftIcon from "@/assets/icon/ChevronLeftIcon";
import ChevronRightIcon from "@/assets/icon/ChevronRightIcon";
import { getCustomersSubscriptionDetails, getEvents, getUserDetails } from "@/axios/get-request";
import getFirstChar from "@/utils/getFirstChar";
import useClickOutside from "@/hooks/useClickOutside";
import LogoutIcon from "@/assets/icon/LogoutIcon";
import SettingsCogIcon from "@/assets/icon/SettingsCogIcon";
import useAuth from "@/hooks/useAuth";
import { useEvent } from "@/hooks/useEvent";
import smallLogo from "@/assets/img/planaday_logo_small.png";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import MenuIcon from "@/assets/icon/MenuIcon";
import useFloorPlan from "@/hooks/useFloorPlan";
import { useBudget } from "@/hooks/useBudget";
import InfoBanner from "@/components/InfoBanner";
import payStackConfig, { payStackSubscriptionConfig } from "@/payStackConfig";
import { subscribeToPlan } from "@/axios/post-request";
import { initializePaymentType, Pricing, SubscriptionType } from "@/data/types";
import EventSwitcher from "@/components/EventSwitcher";
import { isTodayWithinRange } from "@/helper";
import { SubscriptionStatus } from "@/types";
import "@/styles/dashboard_new.scss";

interface Props {
    className?: string;
    title: string;
    description?: string;
    onUpdateEventHandler?: (eventIndex: number) => void;
}

const subscriptionText = {
    freeTrialUser: "You're currently in free trial mode for a month, and a subscription will be required starting",
    freeTrialEnded: "Please subscribe to enjoy stress free planning, your free trial ended on",
    hasNotStartedFreeTrial: "Subscribe to our free trial, use our platform for 30-days free",
    renewSubscription: "Please renew your subscription to enjoy stress free planning, your subscription ended on",
};

export default function NewDashboardLayout({ title, description, children, onUpdateEventHandler, className = "" }: PropsWithChildren<Props>) {
    const [activeEventIndex, setActiveIndex] = useState<number | null>(null);
    const { activeCeremonyId, setActiveCeremonyId, selectedEventId, setSelectedEventId } = useEvent();
    const { data: __eventsData } = useQuery({
        queryKey: [`get_event_${selectedEventId}`],
        queryFn: () => getEvents(),
    });
    const eventsData = __eventsData?.data;
    const { setBudget, setBudgetCategoryId } = useBudget();
    const { toggleSidebar } = useFloorPlan();
    const navigate = useNavigate();
    const location = useLocation();

    const tablet = useMediaQuery("(max-width: 1100px)");
    const mobile = useMediaQuery("(max-width: 767px)");
    const { signUserOut } = useAuth();
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const activeEvent = selectedEventId && eventsData?.result ? eventsData?.result.filter((item) => item.id === selectedEventId) : null;
    const { data } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => getUserDetails(),
    });
    const { data: subscriptionData } = useQuery({
        queryKey: ["subscriptionDetails"],
        enabled: !!data?.result?.id,
        queryFn: () => getCustomersSubscriptionDetails(data?.result?.id as string),
    });
    const ref = useRef(null);
    const queryClient = useQueryClient();
    useClickOutside(ref, closeDropdown);

    const subscriptionStatus = data?.result?.subscription_status;
    const customerSubscriptions = subscriptionData?.data?.result?.subscriptions as Array<SubscriptionType>;
    const hasPaidForThisMonth =
        customerSubscriptions &&
        Array.isArray(customerSubscriptions) &&
        customerSubscriptions.filter((item) => item.status === "active" && isTodayWithinRange(item.createdAt as string, item.next_payment_date))
            .length === 1
            ? true
            : false;

    function sortDateDesc(datesArray: Array<SubscriptionType>) {
        return datesArray.sort((a, b) => {
            const date1 = new Date(b.createdAt);
            const date2 = new Date(a.createdAt);

            return Number(date1) - Number(date2);
        });
    }

    const sortCustomerSubscriptionsByDate =
        customerSubscriptions && Array.isArray(customerSubscriptions) ? sortDateDesc(customerSubscriptions) : null;

    const hasUserSubscribedForTheMonth = subscriptionStatus === SubscriptionStatus.ACTIVE && hasPaidForThisMonth;

    function updateBudgetCategory() {
        if (activeEvent && activeEvent[0]?.budget && activeEvent[0]?.budget?.categories[0]?.id) {
            setBudget(activeEvent[0]?.budget);
            setBudgetCategoryId(activeEvent[0]?.budget?.categories[0]?.id);
        }
    }

    function selectDefaultCeremony() {
        if (activeEvent && activeEvent[0]?.ceremonies && Array.isArray(activeEvent[0]?.ceremonies) && activeEvent[0]?.ceremonies.length > 0) {
            setActiveCeremonyId(activeEvent[0]?.ceremonies[0].id);
        }
    }

    useEffect(() => {
        if (activeEventIndex === null && selectedEventId && eventsData?.result) {
            const eventIndex = eventsData?.result?.findIndex((item) => item.id === selectedEventId);
            setActiveIndex(eventIndex);
        }
        if (eventsData?.result && Array.isArray(eventsData?.result) && eventsData?.result?.length > 0 && !selectedEventId) {
            const userEventId = eventsData.result[0]?.id;
            setSelectedEventId(userEventId);
            if (
                Array.isArray(eventsData?.result[0]?.ceremonies) &&
                eventsData?.result[0]?.ceremonies.length > 0 &&
                eventsData?.result[0]?.ceremonies[0]?.id &&
                !activeCeremonyId
            ) {
                setActiveCeremonyId(eventsData.result[0]?.ceremonies[0]?.id);
            }
            queryClient.invalidateQueries({ queryKey: [`get_budget_categories_${userEventId}`, `ceremony_${activeCeremonyId}`, "get_all_events"] });
        }
    }, [eventsData]);

    function closeDropdown() {
        setShowProfileDropdown(false);
    }

    function goBackHandler() {
        if (location.pathname.includes("dashboard")) {
            return navigate(-1);
        }
    }

    function nextHandler() {
        if (location.pathname.includes("dashboard")) {
            return navigate(+1);
        }
    }

    const config = data?.result?.subscription_details
        ? payStackSubscriptionConfig(data?.result?.email as string)
        : payStackConfig(data?.result?.email as string, Pricing.Test_account_validity);
    const initializePayment = usePaystackPayment(config) as initializePaymentType;

    async function makePaymentHandler() {
        try {
            initializePayment(() => {
                return subscribeToPlanHandler();
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    async function subscribeToPlanHandler() {
        const currentDate = new Date();
        /**
         * default subscription date is October 2,
         */
        // const defaultDate = set(currentDate, { year: 2024, month: 9, date: 2 });

        // const subscriptionStartDate = currentDate > defaultDate ? currentDate : defaultDate;

        const subcriptionStartDetails = data?.result?.subscription_details
            ? {
                email: data?.result?.email as string,
                startDate: currentDate.toISOString(),
            }
            : {
                email: data?.result?.email as string,
                startDate: addMonths(currentDate, 1).toISOString(),
            };

        await subscribeToPlan(subcriptionStartDetails);
    }

    const userName = data?.result?.name ? data?.result?.name : null;
    const businessName = data?.result?.business_name ? data?.result?.business_name : null;
    const name = userName || businessName;

    const showBanner = !hasUserSubscribedForTheMonth;

    const showBannerClassName = showBanner ? "banner_info" : "";

    const lastSubscriptionDetails = sortCustomerSubscriptionsByDate
        ? sortCustomerSubscriptionsByDate[sortCustomerSubscriptionsByDate.length - 1]
        : null;

    const renewSubscriptionText =
        data?.result?.subscription_details && lastSubscriptionDetails && new Date() > new Date(lastSubscriptionDetails.next_payment_date)
            ? `${subscriptionText.renewSubscription} ${format(new Date(lastSubscriptionDetails.createdAt), "MMMM dd, yyyy")}`
            : "";

    const freeTrialSubscriptionText =
        data?.result?.subscription_details && lastSubscriptionDetails?.next_payment_date
            ? `${subscriptionText.freeTrialUser} ${format(new Date(lastSubscriptionDetails?.next_payment_date), "MMMM dd, yyyy")}`
            : "";

    const freeTrialEndText =
        data?.result?.subscription_details &&
        data?.result?.subscription_details?.next_payment_date &&
        new Date() > new Date(data?.result?.subscription_details?.next_payment_date)
            ? `${subscriptionText.freeTrialEnded} ${format(new Date(data?.result?.subscription_details?.next_payment_date), "MMMM dd, yyyy")}`
            : "";

    let bannerText = "";

    if (renewSubscriptionText) {
        bannerText = renewSubscriptionText;
    } else if (freeTrialSubscriptionText) {
        bannerText = freeTrialSubscriptionText;
    } else if (data?.result?.subscription_details && freeTrialEndText) {
        bannerText = freeTrialEndText;
    } else {
        bannerText = subscriptionText.hasNotStartedFreeTrial;
    }

    const mediaQueryClassName = tablet && !mobile ? "tablet__view" : mobile ? "mobile__view" : "";

    return (
        <>
            <Helmet>
                <title>{title} - Planaday | Effortless Event Planning & Management</title>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta
                    name="description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta
                    name="keywords"
                    content="nigerian wedding, asoebi, ankara, lagos wedding, wedding budget, wedding cost, Planaday, plan an event, wedding planning,
                    event planning, event budgeting, event manager, event planner, wedding planner, planning assistant"
                />
                <meta property="og:title" content="Planaday | Effortless Event Planning & Management" />
                <meta
                    property="og:description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta property="og:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta property="og:url" content="https://www.planaday.events" />
                <meta property="og:site_name" content="Planaday Events" />
                <meta property="og:type" content="website" />
                <meta property="og:type" content="Planaday Events" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Planaday | Effortless Event Planning & Management" />
                <meta
                    name="twitter:description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta name="twitter:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:image:alt" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:site" content="@Planaday_events" />
                <meta name="twitter:creator" content="@Planaday_events" />
            </Helmet>
            {showBanner && subscriptionStatus !== SubscriptionStatus.ACTIVE && (
                <InfoBanner className="new_dashboard" text={bannerText}>
                    {data?.result?.email && subscriptionStatus !== SubscriptionStatus.TRIAL && (
                        <button className="mx-3" onClick={makePaymentHandler}>
                            Subscribe
                        </button>
                    )}
                </InfoBanner>
            )}
            <div className={`dashboard_layout ${mediaQueryClassName} ${showBannerClassName}`}>
                {!tablet && (
                    <NewDashboardSidebar
                        showFloorPlan={true}
                        selectDefaultCeremony={selectDefaultCeremony}
                        updateBudgetCategory={updateBudgetCategory}>
                        <EventSwitcher onUpdateEventHandler={onUpdateEventHandler} />
                    </NewDashboardSidebar>
                )}
                <div className={`dashboard_content ${className}`}>
                    <div className="dashboard_header_top_content">
                        {tablet && (
                            <NewDashboardSidebar
                                showFloorPlan={true}
                                selectDefaultCeremony={selectDefaultCeremony}
                                updateBudgetCategory={updateBudgetCategory}>
                                <EventSwitcher onUpdateEventHandler={onUpdateEventHandler} />
                            </NewDashboardSidebar>
                        )}
                        <header className="dashboard_header bg-white d-flex">
                            {mobile && (
                                <div className="header_button_group">
                                    <button onClick={toggleSidebar}>
                                        <MenuIcon />
                                    </button>
                                    <Link to="/dashboard" className="logo">
                                        <img src={smallLogo} alt="Planaday logo" className="logo-img" />
                                    </Link>
                                </div>
                            )}
                            <div className="header_left">
                                <div className="button_group">
                                    <button type="button" onClick={goBackHandler}>
                                        <ChevronLeftIcon />
                                    </button>
                                    <button type="button" onClick={nextHandler}>
                                        <ChevronRightIcon />
                                    </button>
                                </div>
                                <div>
                                    <h3>{title}</h3>
                                    {description && <p>{description}</p>}
                                </div>
                            </div>
                            <div className="header_right">
                                {/* <div className="search_wrapper">
                                <Search fill="#828282" />
                                <input placeholder="Search" />
                            </div> */}
                                <div className="control_group" onClick={() => setShowProfileDropdown((prev) => !prev)}>
                                    {/* <button>
                                    <AlarmIcon />
                                </button> */}
                                    <span>{name ? getFirstChar(name) : <UserRoundIcon />}</span>
                                    <button>
                                        <ChevronDownIcon />
                                    </button>
                                    {showProfileDropdown && (
                                        <div ref={ref} className="control_group_dropdown">
                                            <Link to="/dashboard/settings">
                                                <UserRoundIcon /> View Profile
                                            </Link>
                                            <Link to="/dashboard/settings/account">
                                                <SettingsCogIcon /> Account Settings
                                            </Link>
                                            <button className="logout" onClick={() => signUserOut()}>
                                                <span>
                                                    <LogoutIcon /> Log out
                                                </span>
                                                <span className="app_version">v2.1</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </header>
                    </div>
                    <main className={`dashboard_main ${className}`}>
                        <div className="d-flex dashboard_layout_wrapper">
                            <div className="dashboard_content">{children}</div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
