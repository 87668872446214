import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState, Suspense, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { getAsoebiOrderFormsOrders, getAsoebiQuesionnaireDetails, getAsoebiVendorDetails } from "@/axios/get-request";
import AsoebiDashboardLayout from "@/layout/AsoebiDashboardLayout";
import { formatCurrency } from "@/helper";
import { RequestType, Asoebi_order_status } from "@/types";
import UpdateAsoebiOrderModal from "@/components/modals/UpdateAsoebiOrderModal";
import { useModal } from "@/hooks/useModal";
import useToast from "@/hooks/useToast";
import { updateOrderStatus } from "@/axios/put-request";
import AseobiDashboardLoader from "@/components/loaders/AseobiDashboardLoader";
import useAsoebiAdminAuth from "@/hooks/useAsoebiAdminAuth";
import AsoebiOrdersTable from "@/components/tables/AsoebiOrdersTable";
import ViewAsoebiOrderFormModal from "@/components/modals/ViewAsoebiOrderForm";
import PencilIcon from "@/assets/icon/PencilIcon";

type orderStatusType = keyof typeof Asoebi_order_status | null;

export default function OrderFormDetails() {
    const { verifyData } = useAsoebiAdminAuth();
    const { modal, setModal } = useModal();
    const [selectedEventName, setSelectedEventName] = useState<string | null>(null);
    const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
    const queryClient = useQueryClient();
    const { loadingToast, updateToast } = useToast();
    const toastId = useRef("update_order_status");
    const [OrderStatus, setOrderStatus] = useState<orderStatusType>(null);
    const [searchParams] = useSearchParams();
    const orderformId = searchParams.get("id") as string;

    const { data: orderformDetails } = useQuery({
        queryFn: () => getAsoebiQuesionnaireDetails(orderformId),
        queryKey: [`orderform_details_${orderformId}`],
        enabled: !!orderformId,
    });

    const { data, status } = useQuery({
        queryFn: () => getAsoebiOrderFormsOrders(orderformId),
        queryKey: [`orderform_orders_${orderformId}`],
        enabled: !!orderformId,
    });

    const { data: adminData, status: adminStatus } = useQuery({
        queryFn: () => getAsoebiVendorDetails(verifyData?.data.result.email as string),
        queryKey: ["admin_data"],
        enabled: !!verifyData?.data.result.email,
    });

    const no_of_answered_orders = status === "success" ? data?.data?.result ? data?.data?.result.length : 0 : 0;
    const orders_in_dollar = [];

    const currency = orders_in_dollar.length > 0 ? "$" : "₦";
    const amountText = currency === "$" ? "Total Amount" : "Total amount collected";

    function onCloseModal() {
        setModal(null);
    }

    function updateOrderStatusHandler(orderStatus: orderStatusType, eventName: string, orderId: string | null) {
        setOrderStatus(orderStatus);
        setSelectedEventName(eventName);
        setSelectedOrderId(orderId);
        setModal("update_asoebi_status_modal");
    }

    const showUpdateAsoebiOrderStatusModal = modal === "update_asoebi_status_modal";

    async function onUpdateStatusHandler() {
        try {
            if (selectedOrderId && OrderStatus) {
                loadingToast(toastId);
                const updateOrderRequest = await updateOrderStatus(selectedOrderId, OrderStatus);
                if (updateOrderRequest.data.type !== RequestType.Success) {
                    return updateToast(toastId, "error", updateOrderRequest.data.message || "Oops an error occured, unable to update status");
                }
                updateToast(toastId, "success", updateOrderRequest.data.message || "Order status updated successfully");
                setSelectedEventName(null);
                setSelectedOrderId(null);
                setModal(null);
                queryClient.resetQueries({ queryKey: ["get_vendor_asoebi_orders"] });
            }
        } catch (error: unknown) {
            const _error = error as { message: string };
            return updateToast(toastId, "error", _error.message || "Oops an error occured, unable to update status");
        }
    }

    const plannerName = adminStatus === "success" ? adminData?.data.result?.name || adminData?.data.result?.email : "";

    const totalAmountPaid = orderformDetails?.result?.total_amount_paid ? orderformDetails?.result?.total_amount_paid : 0;

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                {modal === "update_asoebi_status_modal" ? (
                    <UpdateAsoebiOrderModal
                        show={showUpdateAsoebiOrderStatusModal}
                        orderStatus={OrderStatus}
                        eventName={selectedEventName}
                        onHide={onCloseModal}
                        onUpdateStatusHandler={onUpdateStatusHandler}
                    />
                ) : (
                    modal === "view_asoebi_order_form_modal" && (
                        <ViewAsoebiOrderFormModal show={true} orderFormDetails={orderformDetails?.result} onHide={onCloseModal} />
                    )
                )}
            </Suspense>

            <AsoebiDashboardLayout type="user" name={plannerName}>
                <>
                    {status === "error" ? (
                        <p>Unable to fetch admin details</p>
                    ) : status === "loading" ? (
                        <AseobiDashboardLoader />
                    ) : (
                        <div className="guest_dashboard">
                            <div className="top_content d-flex justify-content-between">
                                <h1 className="text-dark">{orderformDetails?.result.eventDetails.event_name} Order form </h1>
                                <div>
                                    <button
                                        className="btn btn-primary rounded font-weight-bold"
                                        title="view orderform"
                                        onClick={() => setModal("view_asoebi_order_form_modal")}>
                                        View order form
                                    </button>
                                    <a
                                        className="btn ml-3 bg-base rounded font-weight-bold"
                                        title="update orderform"
                                        href={`/asoebi-tracker/update-order-form?id=${orderformDetails?.result.id}`}
                                        target="_blank">
                                        <PencilIcon />
                                    </a>
                                </div>
                            </div>
                            <section className="card_view">
                                <div className="card  bg-white">
                                    <h2>{no_of_answered_orders}</h2>
                                    <h4 className="mb-0">Asoebi Orders</h4>
                                </div>
                                <div className="card  bg-white">
                                    <h2>{formatCurrency(totalAmountPaid, currency)}</h2>
                                    <h4 className="mb-0">{amountText}</h4>
                                </div>
                            </section>
                            {data?.data?.result && (
                                <AsoebiOrdersTable updateOrderStatusHandler={updateOrderStatusHandler} orders={data?.data?.result} />
                            )}
                        </div>
                    )}
                </>
            </AsoebiDashboardLayout>
        </>
    );
}
